<template>
  <div>
    <teleport selector="#button">
      <b-button
        class="px-6 general-add-new-btn btn-primary-light"
      >
        <b-icon
          icon="plus"
          size="is-small"
        />New test
      </b-button>
    </teleport>

    <div
      v-if="!isTestFetchDone"
      class="columns is-vcentered px-5"
    >
      <div class="is-10 column">
        <b-skeleton height="30" />
        <b-skeleton height="50" />
      </div>
      <div class="is-2 column">
        <b-skeleton height="30" />
      </div>
    </div>

    <NoResults
      v-else-if="tests.length === 0"
      banner-label="No tests yet, create one now."
      button-label="New test"
      :button-handler="openNewTestModal"
    />

    <b-table
      v-else
      :data="tests"
      class="tab-table-layout"
      @click="goToTest"
    >
      <b-table-column
        v-slot="{ row }"
        label="Name"
        field="name"
      >
        {{ row.name }}
      </b-table-column>
    </b-table>

    <BaseModal
      v-model="newTestModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New test"
      aria-modal
    >
      <CardPopup
        title="New test"
        class="w-800"
        @hide="newTestModalActive = false"
      >
        <template #body>
          <b-notification
            v-if="isTestCreationFailed"
            :closable="false"
            size="is-small"
            type="is-danger"
          >
            Please enter all required information
          </b-notification>
          <b-field label="Test name">
            <b-input
              v-model="test.name"
              type="text"
              placeholder="e.g. My awesome test"
              required
            />
          </b-field>
          <b-field label="Application you want to test">
            <b-select v-model="test.application_id">
              <option
                v-for="(application, index) in applications"
                :key="index"
                :value="application.id"
              >
                {{ application.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Module you want to test">
            <b-select v-model="test.module_id">
              <option
                v-for="(module, index) in modules"
                :key="index"
                :value="module.id"
              >
                {{ module.name }}
              </option>
            </b-select>
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="newTestModalActive = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="createTest()"
            >
              Create
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { computed, defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { fetchAutoTestService } from '@/services/autotest-service/moduleTestRequests';
import { useRouter } from '@/hooks/vueRouter';
const NoResults = defineAsyncComponent(() => import('@/modules/core/components/NoResults.vue'));
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const moduleStore = useModuleStore();
  const isTestFetchDone = ref(false);
  const tests = ref([]);
  const test = ref({
    application_id: null,
    module_id: null,
    name: null
  });
  const applications = ref([]);
  const modules = ref([]);
  onMounted(() => {
    fetchTests();
  });

  const fetchTests = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchAutoTestService({});
      tests.value = data;
      isTestFetchDone.value = true;
    } catch (err) {
      console.error(err);
    }
  };

  const goToTest = async tableRow => {
    const {
      id
    } = tableRow;
    router.push('/test-center/' + id);
  };

  const createTest = async () => {};

  const openNewTestModal = async () => {};

  const newTestModalActive = computed({
    get() {
      return moduleStore.isNewTestModalActive;
    },

    set(value) {
      moduleStore.setTestActivity(value);
    }

  });
  return {
    isTestFetchDone,
    tests,
    test,
    applications,
    modules,
    goToTest,
    createTest,
    openNewTestModal,
    newTestModalActive
  };
};

__sfc_main.components = Object.assign({
  NoResults
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';
</style>